import React from "react"
import Layout from "../components/layout"
import { graphql } from 'gatsby';
import SEO from "../components/seo";

class Offer extends React.Component {
    render() {
        const { data } = this.props;
        const siteTitle = data.site.siteMetadata.title;
    
        return (
          <Layout location={this.props.location} title={siteTitle}>
            <SEO title="Poltyka prywatności" />
            <div className="container mx-auto privacy-policy py-10">
              <div className="sm:flex sm:items-center">
                <div className="inline-flex w-12 h-12 items-center justify-around bg-main rounded-lg">
                  <svg className="fill-current text-white w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 0C114.51 0 0 114.497 0 256c0 141.49 114.497 256 256 256 141.49 0 256-114.497 256-256C512 114.51 397.503 0 256 0zm0 477.867c-122.337 0-221.867-99.529-221.867-221.867S133.663 34.133 256 34.133 477.867 133.663 477.867 256 378.337 477.867 256 477.867z"/>
                    <path d="M255.997 209.777c-9.425 0-17.067 7.641-17.067 17.067v143.969c0 9.425 7.641 17.067 17.067 17.067s17.067-7.641 17.067-17.067v-143.97c-.001-9.426-7.642-17.066-17.067-17.066zM256 124.122c-18.821 0-34.133 15.312-34.133 34.133s15.312 34.133 34.133 34.133 34.133-15.312 34.133-34.133-15.312-34.133-34.133-34.133z"/>
                  </svg>
                </div>
                <h1 className="text-4xl leading-none font-bold text-gray-800 ml-4">Polityka prywatności</h1>
              </div>
              {/*<p className="text-gray-700 mt-3 lg:w-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit. A asperiores at delectus dolorum earum esse eum explicabo fuga fugiat, id inventore laboriosam, obcaecati odio, officia pariatur praesentium reprehenderit vero voluptate!</p>*/}

              <div className="py-6 markdown">
                <p>W związku z wejściem w życie w dniu 25 maja 2018 r. Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), zwanym dalej „<b>RODO</b>”, zgodnie z art. 13 ust. 1 i ust. 2 RODO oraz dbałością firmy Specjalistyczne Centrum Medyczne LENSMED sp. z o.o. o przetwarzanie i ochronę danych osobowych odwiedzających niniejszą stronę internetową osób informujemy jak poniżej.</p>
                <ol>

                <li className="mt-4"><b>Kto jest Administratorem Twoich danych?</b><br/>
                <p>Administratorem Pani/Pana danych osobowych jest firma Specjalistyczne Centrum Medyczne LENSMED sp. z o.o. z siedzibą w Sandomierzu przy ulicy Mickiewicza 15.</p>
                <br/>
                <p>Dane kontaktowe:
                e-mail: recepcja@lens-med.pl<br/>NIP: 8641949222<br/>KRS: 0000415441<br/>REGON: 260594908</p>
                </li>

                <li><b>Jakie dane mogą być przetwarzane?</b><br/>
                <p>W zakresie niezbędnym do osiągnięcia celu, przetwarzane mogą być następujące podane dobrowolnie dane:</p>
                <ol>
                  <li>imię i nazwisko,</li>
                  <li>adres e-mail</li>
                  <li>numer telefonu</li>
                </ol>
                </li>

                <li><b>Jaki jest cel przetwarzania? </b><br/>
                <p>Pani/Pana dane osobowe przetwarzane są w celach:</p> <ol>
                  <li>zawarcia i realizacji umowy (zakupu produktów lub usług),</li>
                    <li>prowadzenia analiz i statystyk na potrzeby prowadzonej działalności gospodarczej, </li>
                  <li>prowadzenie działań marketingowych samodzielnie lub we współpracy z innymi podmiotami, </li>
                  <li>dochodzenia roszczeń, </li>
                  <li>archiwizacji, </li>
                  <li>udzielania odpowiedzi na zapytania, pisma lub wnioski, </li>
                  <li>prowadzenia działań związanych z zapewnieniem bezpieczeństwa, </li>
                  <li>wypełniania obowiązków prawnych ciążących na administratorze danych, </li>
                </ol></li>

                  <li><b>Jaka jest podstawa przetwarzania danych? </b><br/>
                <p>Podstawą przetwarzania Pani/Pana danych będzie:</p><ol>
                  <li>art. 6 pkt 1 lit. b) Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) (Dz. Urz. UE L nr 119 str. 1) („RODO”) w celu realizacji i obsługi umowy zawartej z Administratorem danych, .</li>
                <li>art. 6 ust. 1 lit. c) RODO w związku z koniecznością wypełnienia obowiązków prawnych przez Administratora Danych, w tym obowiązków podatkowych, .</li>
                  <li>art. 6 pkt 1 lit. f) RODO w prawnie usprawiedliwionym interesie Administratora danych jakim jest ochrona przed ewentualnymi roszczeniami na drodze cywilnej związanymi z realizacją wskazanej wyżej umowy, .</li>
                  <li>art. 6 pkt 1 lit. f) RODO w prawnie usprawiedliwionym interesie Administratora danych jakim jest obsługa Pani/Pana zapytania oraz ochrona przed ewentualnymi roszczeniami na drodze cywilnej związanymi z realizacją tego zapytania.</li></ol></li>

                <li><b>Kto może być odbiorcą danych? </b><br/>
                <p>Pani/Pana dane będą przetwarzane przez pracowników i współpracowników administratora w zakresie niezbędnym do realizacji celu przetwarzania danych. Odbiorcą twoich danych będą wszelkiego rodzaju osoby lub podmioty współpracujące z administratorem w celu realizacji celu przetwarzania danych, przykładowo:</p>
                <ol>
                  <li>podmioty świadczące usługi księgowe,</li>
                  <li>podmioty lub organy uprawniony na podstawie przepisów prawa,</li>
                </ol>
                </li>

                <li><b>Czy dane mogą być przekazane poza terytorium EOG? </b><br/>
                <p>Pani/Pana dane nie będą przekazywane poza terytorium Europejskiego Obszaru  Gospodarczego (EOG).</p></li>

                <li><b>Jaki jest okres przetwarzania danych?</b><br/>
                <p>Pani/Pana dane osobowe przechowywane będą przez okres niezbędny do realizacji celu przetwarzania, a w szczególności:</p><ol>
                <li>odpowiedzi na Pani/Pana zapytanie,</li>
                <li>realizacji i obsługi zawartej z Panią/Panem umowy,</li>
                <li>wykonywania obowiązków wynikających z przepisów prawa, w szczególności podatkowych i rachunkowych,</li>
                <li>dochodzenia roszczeń wynikających z zawartej umowy,</li>
                </ol></li>

                <li><b>Jakie są Pani/Pana uprawnienia?</b><br/>
                <p>Ma Pani/Pan prawo do dostępu do treści swoich danych oraz prawo ich sprostowania, usunięcia, ograniczenia przetwarzania, przenoszenia, wniesienia sprzeciwu, cofnięcia zgody w dowolnym momencie.</p></li>

                <li><b>Gdzie może Pani/Pan złożyć skargę?</b><br/>
                <p>Ma Pani/Pan prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, gdy uzna Pani/Pan, iż przetwarzanie Pani/Pana danych osobowych dotyczących narusza przepisy ogólnego rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 r.</p></li>

                  <li><b>Czy musi Pani/Pan podać swoje dane osobowe?</b><br/>
                <p>Podanie przez Panią/Pana danych osobowych jako osoby fizycznej jest dobrowolne, ale konieczne w celu zawarcia i wykonania zawartej z Panią/Panem umowy oraz udzielenia odpowiedzi na Pani/Pana zapytanie.</p>
                </li>
                  <li><b>Czy Pani/Pana dane są profilowane?</b><br/>
                <p>Pani/Pana dane nie będą poddawane procesowi zautomatyzowanego podejmowaniu decyzji, w tym profilowaniu.</p></li></ol>
              </div>
            </div>
        </Layout>
        )
    }
}

export default Offer;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
}`